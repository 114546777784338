.ant-carousel{
  /* width: inherit; */
  flex-shrink: 0;
  flex-grow: 1;
}

.slick-slider{
  height: 100%;
}

.slick-list{
  height: 100%;
}

.slick-track{
  height: 100%;
}

.slick-dots.slick-dots-bottom{
  bottom: 10px !important;
}

.ant-image-mask-info{
  padding: 0px;
}