@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fff;
}

@layer base{
  :root{
    --base-color-primary: #feca05;
    --height-header: 120px;
    --height-header-mobile: 80px;
  }

}

